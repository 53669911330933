/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Alertes banniere - styles bases
 * Alertes banniere - styles complexes
 * Alertes popup - styles bases
 */

/****** Alertes banniere - styles bases ******/
.alertesBanniere{
    background-color: #333;
    margin:0;
    padding: 0;
    border: 0;
    overflow: auto;
    &__item{
        position: relative;
        margin: 20px;
        padding: 20px;
        border-radius: 5px;
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
        display: flex;
        justify-content: space-between;
        a {
            color: blue;
        }
    }
}


/****** Alertes banniere - styles complexes ******/
.alertsBanner{
    background-color: red;
    overflow: hidden;
    display: none;
    &__wrapper{
        position: relative;
    }
    &__list{
        padding-right: 80px;
    }
    &__nav{
        position: absolute;
        right: 0;
        color: white;
        display: flex;
        top: 50%;
        transform: translate(0, -50%);
    }
    &__close{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 50px;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 1;
        transition: opacity 0.3s ease;
        cursor: pointer;
        &::before,
        &::after{
            content: '';
            background-color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 2px;
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after{
            transform: translate(-50%,-50%) rotate(-45deg);
        }
        &:hover{
            opacity: 0.5;
        }
    }
    &__alert{
        &>a,
        &>span{
            color: white;
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            h5,p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            h5{
                font-weight: bold;
            }
        }
    }
}

// Mettre à show l'alerte active
.showAlerts{
    .alertsBanner{
        display: block;
    }
}


/****** Alertes popup - styles bases ******/
.alertesPopup{
    border-radius: 5px;
    p, a{
        margin: 10px;
    }
    &__conteneurLink{
        text-align: right;
    }
    // Enlever button close fancybox default
    > button:last-child{
        display: none;
    }
}
