// |--------------------------------------------------------------------------
// | Les Font-face
// |--------------------------------------------------------------------------
// |
// | La déclaration des polices de caractères
// |--------------------------------------------------------------------------

// |--------------------------------------------------------------------------
// | TODO: Ajouter les variables des polices de caractères
// |--------------------------------------------------------------------------
    @font-face {
        font-family: 'Founders Grotesk';
        src: url('../fonts/FoundersGroteskLight.otf');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Founders Grotesk';
        src: url('../fonts/FoundersGroteskRegular.otf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Founders Grotesk';
        src: url('../fonts/FoundersGroteskRegularItalic.otf');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Founders Grotesk';
        src: url('../fonts/FoundersGroteskMedium.otf');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Founders Grotesk';
        src: url('../fonts/FoundersGroteskBold.woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Founders Grotesk Mono';
        src: url('../fonts/FoundersGroteskMonoMedium.woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
// |--------------------------------------------------------------------------