:root{
  --social__size: 35px;
  --social__icon-size: 16px;
}

.social{
  display: flex;

  &__item{
    margin-left: 15px;
    &:first-child{ margin-left: 0; }
  }

  &__link{
    border-radius: 50%;
    width: var(--social__size);
    height: var(--social__size);
    display: block;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%,-50%);
      background-color: transparent;
      border: 1px solid black;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &__svg{
    position: absolute;
    width: var(--social__icon-size);
    height: var(--social__icon-size);
    fill: black;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
  }
}