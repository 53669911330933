:root {
    // Bouton primaire
    --button-background: var(--color-blue-base);
    --button-color: white;
    --button-border-color: var(--color-blue-base);

    --button-background-hover: var(--color-blue-hover);
    --button-color-hover: white;
    --button-border-color-hover: var(--color-blue-hover);

    // Bouton secondaire
    --button-secondary-background: transparent;
    --button-secondary-color: var(--color-blue-base);
    --button-secondary-border-color: var(--color-blue-base);

    --button-secondary-background-hover:  var(--color-blue-base);
    --button-secondary-color-hover: white;
    --button-secondary-border-color-hover:  var(--color-blue-base);
}

.button,
.button--secondary{
    color: var(--button-color);
    position: relative;
    background-color: var(--button-background);
    font-weight: 600;
    font-size: 1.4rem;
    padding: 22px 40px;
    border: 1px solid var(--button-border-color);
    transition: color 0.3s, background-color 0.3s, border 0.3s;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    line-height: 1.3em;
    border-radius: 100px;
    cursor: pointer;

    svg{
        content: '';
        fill: var(--button-color);
        display: inline-block;
        margin-left: 10px;
        transition: fill 0.3s;
        flex-shrink: 0;
    }

    &[target="_blank"],
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"]{
        svg{
            width: 11px;
            height: 11px;
        }
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"]{
        svg{
            width: 12px;
            height: 12px;
        }
    }

    &:hover{
        color: var(--button-color-hover);
        background-color: var(--button-background-hover);
        border-color: var(--button-border-color-hover);
        svg{
            fill: var(--button-color-hover);
        }
    }

}

// Bouton secondaire
.button--secondary{
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-background);
    border-color: var(--button-secondary-border-color);
    svg{
        fill: var(--button-secondary-color);
    }

    &:hover{
        color: var(--button-secondary-color-hover);
        background-color: var(--button-secondary-background-hover);
        border-color: var(--button-secondary-border-color-hover);
        svg{
            fill: var(--button-secondary-color-hover);
        }
    }
}

// Bouton de recherhe
.button-search {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border: 1px solid black;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 900px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }

    &__svg, &__x {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 400px) {
            width: 16px;
            height: 16px;
        }
    }

    &__svg {
        width: 19px;
        height: 19px;
        opacity: 1;
        stroke: black;
        stroke-width: 12px;
        fill: none;
        transition: opacity 300ms var(--ease__in-out-cubic);

        @media screen and (max-width: 700px) {
            width: 17px;
            height: 17px;
        }
    }

    &__x {
        width: 15px;
        height: 15px;
        opacity: 0;
        transition: opacity 300ms var(--ease__in-out-cubic);
        stroke: black;
        fill: black;
    }
}

// Bouton de menu
.button-menu {
    position: relative;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    background: var(--button-background);
    cursor: pointer;

    @media screen and (max-width: 900px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }

    &__lines {
        position: absolute;
        width: 20px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 400px) { width: 18px; }
    }

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: white;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms;
    }

    &__line1 { top: 0px; }
    &__line2, &__line3 { top: calc(50% - 1px); }
    &__line4 { bottom: 0px; }
}

// Bouton de navigation
.button-navigation,
.button-navigation {
    width: 60px;
    height: 60px;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 900px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }

    @media screen and (max-width: 400px) {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }

    &__svg{
        width: 17px;
        height: 14px;
        stroke: black;
        fill: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        will-change: transform;
    }
}

.button-navigation--left{
    transform: rotate(180deg);
}

