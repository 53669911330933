/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Shocases list - styles bases
 */

/****** Shocases list - styles bases ******/
// Liste de vitrines
.showcases{
    display: flex;
    justify-content: space-between;
    &__item{
        width: 33.33%;
    }

    @media screen and (max-width: 1000px){
        flex-direction: column;
        &__item{
            width: 100%;
        }
    }
}

// Effet hover slm si c'est un lien l'item
a.showcases__item:hover .showcase__background:before {
    background: black;
}

// Vitine item
.showcase{
    height: 250px;
    padding: 0 10px;
    &__background{
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(41, 53, 72, 0.6);
            z-index: 1;
            transition: background 500ms;
        }
    }
    &__txts{
        color: white;
        z-index: 1;
    }
    &__tagline{
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2em;
    }
    &__mention{
        font-size: 17px;
        font-weight: bold;
        line-height: 1.5em;
    }
    &__description{
        margin-top: 10px;
    }
    &__label{
        position: relative;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 30px;
        padding-left: 35px;
        &::before {
            position: absolute;
            content: "";
            background-color: white;
            width: 19px;
            height: 2px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    @media screen and (max-width: 600px){
        &__description{
            display: none;
        }
    }
    @media screen and (max-width: 1000px){
        height: 185px;
        padding: 0 0 20px;
    }
}
