/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Contenus à gauche
 * Tableau marées
 * Contenus indisponible
 * Logo autorité
 * Picture background
 */

.tides{
  background: #00333b;
  position: relative;
  z-index: 1;

  /****** Contenus à gauche ******/
  &__content{
    width: 66.66%;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 80px;
    padding-left: 80px;

    @media screen and (max-width: 1600px){ width: 58.33333%;}
    @media screen and (max-width: 1200px){ width: 66.66667%;}
    @media screen and (max-width: 1024px){ padding-top: 80px; padding-bottom: 80px; padding-right: 40px; padding-left: 40px;}
    @media screen and (max-width: 768px){ padding-top: 60px; padding-bottom: 60px; width: 83.33333%;}
    @media screen and (max-width: 600px){ width: 100%;}

    &-title{
      color: white;
    }

    /****** Tableau marées ******/
    &-table{
      font-size: 22px;
      font-weight: 900;
      color: #fff;
      text-align: center;
      margin: 0 0 50px 0;
      width: 100%;

      tr{
        &:nth-child(odd) td{
          background: #124249;
        }
        &:nth-child(even) td{
          background: #1c4b51;
        }
        &:first-child td{
          border-top: none;
        }
        &:last-child td{
          border-bottom: none;
        }
        td{
          width: 33.33%;
          padding: 30px 10px;
          border: solid 5px #00333b;
          &:first-child{
            border-left: none;
          }
          &:last-child{
            border-right: none;
          }
          &:nth-child(2):after{
            content: '(m)';
          }
          &:nth-child(3):after{
            content: '(pi)';
          }
          &:after{
            font-size: 17px;
            font-weight: 400;
            display: inline;
            margin-left: 10px;
            color: #b0c9c6;
          }
        }
      }

      > p{
        color: white;
      }
    }

    /****** Contenus indisponible ******/
    &-unavailable{
      font-size: 22px;
      font-weight: 500;
      color: #fff;
      margin: 0 0 50px 0;
      width: 100%;
    }

    /****** Logo autorité ******/
    &-bottom{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      a{
        margin: 0 20px 30px 0;
      }
      img{
        vertical-align: middle;
        height: 22px;
        max-width: 100%
      }
    }
  }

  /****** Picture background ******/
  &__picture{
    width: 33.33%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../medias/images/page-home/marees.jpeg") no-repeat center center;
    background-size: cover;
    z-index: -1;
  }
}