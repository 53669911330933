/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Filtre lettres
 * Catégories lexiques
 * Lexiques Item
 * Icone plus
 */

/****** Filtre lettres ******/
.filtreLettre{
    &__list{
        display: flex;
        flex-wrap: wrap;
    }
    &__lien{
        color: white;
        background: black;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        margin: 3px 0 3px 6px;
        &:hover, &:focus, &.active{
            background: #007bff;
        }
    }
}

/****** Catégories lexiques ******/
.categoriesLex{
    &__titre{
        line-height: 1.3;
        font-weight: 500;
        color: #007bff;
        font-size: 2.125rem;
        padding: 30px 0;
    }
}

/****** Lexiques Item ******/
.rubrique{
    &__lienPlus{
        position: relative;
        width: 100%;
        padding: 30px 0;
        > h3 {
            font-size: 1.125rem;
            line-height: 1.3;
            font-weight: 500;
            color: black;
        }
        &:hover, &focus{
            > h3{
                color: #007bff;
            }
        }
    }
    &__list{
        &.ocList .open > a .plus::before {
            opacity: 0;
        }
        &Item{
            border-top: 1px solid #d6d8d9;
            position: relative;
            padding: 0 20px;
            &:last-child {
                border-bottom: 1px solid #d6d8d9;
            }
            .ocListCt{
                display: none;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                left: 0;
                background-color: #f7f8f8;
                width: 100%;
                transform-origin: right;
                transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: scaleX(0);
                z-index: -1;
            }
            &.open::after {
                transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }
    &__description{
        padding: 10px 0 30px;
        a{
            color: #007bff;
        }
    }
}

/****** Icone plus ******/
.plus{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    z-index: 2;
    &::before, &::after{
        content: '';
        position: absolute;
        top: 50%;
        display: block;
        background: #007bff;
        transition: opacity 300ms, background 300ms;
    }
    &::before {
        right: 50%;
        width: 2px;
        height: 20px;
        margin: -9px -1px 0 0;
    }
    &::after {
        right: 0;
        width: 20px;
        height: 2px;
        margin-top: -1;
    }
}
