.page-top{
    background-color: white;
    position: relative;

    &__title{
        //@extend .top-padding-50;
        //@extend .bottom-padding-80;
        //@extend .x-padding-80;

        @media screen and (max-width: 768px) {
            margin-top: -20px;
        }

        h1{
            //@extend .huge-title;
        }
    }

    &__picture{
        //@extend .x-padding-80;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }

        &__wrapper{
            position: relative;
            picture{
                height: 530px;
            }
        }
    }

    // Ajustement des marges si il y a une image ou non
    &:not(.page-top--with-picture):not(.page-top--wide){
        .page-top__title{
            //@extend .x-padding-180;
        }
    }

    &--with-picture, &--wide{
        .page-top__title{
            //@extend .x-padding-80;
        }
    }

}