@use 'components';

:root {
    --dynamic__p-font-line-height: 1.6em; //Doit inclure le 'em' autrement les bullets des listes ne seront pas alignées

    --dynamic__ol-indent: 50px;
    --dynamic__ol-indent-last: 40px;
    --dynamic__ul-indent: 20px;
    --dynamic__ul-bullet-color: black;

    --dynamic__table-header-background: black;
    --dynamic__table-header-color: white;
    --dynamic__table-border-color: #b2b2b2;
    --dynamic__table-secondary-header-background: #e6e6e6;

    --dynamic__p-margin: 25px;
    --dynamic__p-margin-under-button: 15px; //Marge sous les boutons si ils tombent sur 2 lignes
    --dynamic__p-margin-besides-button: 15px; //Marge entre les boutons
    --dynamic__p-margin-button: 40px; //Plus grosse marge pour paragraphe contenant des boutons

    @media screen and (max-width: 768px) {
        --dynamic__ol-indent: 25px;
        --dynamic__ol-indent-last: 25px;
    }
    @media screen and (max-width: 500px) {
        --dynamic__ol-indent: 15px;
        --dynamic__ol-indent-last: 15px;
        --dynamic__ul-indent: 10px;
    }
}


.dynamic{

    color: var(--color-grayscale-black);

    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h1{
        @extend .huge-title;
        margin-bottom: 100px;
        margin-top: 100px;

        @media screen and (max-width: 1024px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    h2{
        @extend .large-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h3{
        @extend .medium-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h4{
        @extend .small-title;
        margin-top: 65px;
        margin-bottom: 30px;
    }

    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6{
        margin-top: 0;
    }

    p{
        font-size: var(--paragraph-font-size);
        margin-top: var(--dynamic__p-margin);
        margin-bottom: var(--dynamic__p-margin);
        line-height: var(--dynamic__p-font-line-height);

        &.intro{
            font-size: var(--introduction-font-size);
            line-height: 1.6em; //80px/70px
            margin-top: 65px;
            margin-bottom: 65px;
        }

        //40px
        &.buttonMargin{
            margin-bottom: calc(var(--dynamic__p-margin) - var(--dynamic__p-margin-under-button));
            margin-left: calc(var(--dynamic__p-margin-besides-button) / -2);
            margin-right: calc(var(--dynamic__p-margin-besides-button) / -2);
            .button,.button--secondary{ // Ajouter une marge sous les boutons
                margin-bottom: var(--dynamic__p-margin-under-button);
                margin-left: calc(var(--dynamic__p-margin-besides-button) / 2);
                margin-right: calc(var(--dynamic__p-margin-besides-button) / 2);
            }
            &--first{ margin-top: var(--dynamic__p-margin-button) }
            &--last{ margin-bottom: calc(var(--dynamic__p-margin-button) - var(--dynamic__p-margin-under-button) ) }
        }
    }

    strong,b{
        font-weight: 700;
    }

    em,i{
        font-style: italic;
    }

    sup{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: -0.3em;
    }
    sub{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: 0.3em; 
    }

    a:not(.button):not(.button--secondary){
        color: var(--color-grayscale-black);
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 var(--color-grayscale-gray80);
        transition: box-shadow 0.3s;

        &.link-image, 
        &.link-image:hover {
            box-shadow: none;
        }

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 var(--color-grayscale-gray80);
        }

        //Icones liens externes et téléchargement
        svg{
            margin-left: 8px;
            width: 11px;
            height: 11px;
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"]{
            svg{
                width: 12px;
                height: 12px;
            }
        }
    }

    ol,
    ul{
        margin-top: 30px;
        margin-bottom: 30px;
        li{
            &:not(:first-child){ margin-top: 25px; }

            font-size: var(--paragraph-font-size);
            line-height: var(--dynamic__p-font-line-height);

            ol,ul{
                margin-top: 20px;
                margin-bottom: 0;
                width: 100%;
                li{
                    &:not(:first-child){ margin-top: 20px; }
                }
            }
        }
    }

    ol{
        list-style: none;
        counter-reset: li;
        & > li{
            position: relative;
            padding-left: var(--dynamic__ol-indent);
            &::before{
                content: counter(li) ".";
                counter-increment: li;
                font-size: 1.6rem;
                font-weight: 500;
                position: absolute;
                display: inline-block;
                left: 0;
                top: calc(var(--dynamic__p-font-line-height) / 2);
                transform: translate(0, -50%);

                @media screen and (max-width: 425px) { font-size: 1.5rem; }
            }

            ol > li{ // <ol> 2e niveau
                &::before{ content: counter(li, lower-alpha) "."; }
                ol > li{ // <ol> 3e niveau
                    &::before{ content: counter(li, lower-roman) "."; }
                    padding-left: var(--dynamic__ol-indent-last);
                }
            }
        }
    }

    ul{
        & > li{
            padding-left: var(--dynamic__ul-indent);
            position: relative;
            &::before{
                content: '';
                width: 6px;
                height: 6px;
                border: 1px solid var(--dynamic__ul-bullet-color);
                background:var(--dynamic__ul-bullet-color);
                position: absolute;
                left: 0;
                transform: translate(0,-50%);
                border-radius: 50%;
                box-sizing: border-box;
                top: calc(var(--dynamic__p-font-line-height) / 2);
            }

            & > ul{
                li{
                    &::before{ background: transparent; }
                    & > ul{
                        li{
                            &::before{ background: var(--dynamic__ul-bullet-color); }
                        }
                    }
                }
            }
        }
    }

    blockquote{
        position: relative;
        padding-left: 30px;
        margin-top: 35px;
        margin-bottom: 35px;
        border-left: 10px solid var(--color-grayscale-gray80);
        p{
            font-size: 2.2rem;
            line-height: 1.5em;
            font-weight: 600;

            @media screen and (max-width: 1024px) { font-size: 2rem; }
            @media screen and (max-width: 768px) { font-size: 1.8rem; }
            @media screen and (max-width: 425px) { font-size: 1.6rem; }
        }
    }

    .emphasis{
        border: 1px solid var(--color-grayscale-gray80);
        margin-top: 30px;
        margin-bottom: 65px;
        padding: 45px 40px;

        & > :first-child{ margin-top: 0; }
        & > :last-child{ margin-bottom: 0}
    }

    .table-wrapper{
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 65px;

        table{
            overflow-x: hidden;
            width: 100%;
            min-width: 600px;
            border-collapse: collapse;
            tr{
                th,td{
                    padding: 20px 35px;
                    line-height: 1.5625em; // 25px/16px
                    border: 1px solid var(--dynamic__table-border-color);
                    font-size: 1.7rem;

                    @media screen and (max-width: 768px) { font-size: 1.6rem; }
                    @media screen and (max-width: 375px) { font-size: 1.5rem; }

                    & > :first-child{ margin-top: 0; }
                    //& > :last-child{ margin-bottom: 0}

                    & > p, & > pre, & > strong, & > em, & > a, & > span, & > sub, & > sup, & > ul, & > ol {
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
                th,
                td.header{
                    background-color: var(--dynamic__table-header-background);
                    font-weight: normal;
                    color: var(--dynamic__table-header-color);
                    border-top-color: var(--dynamic__table-header-background);
                    border-bottom-color: var(--dynamic__table-header-background);
                    text-align: left;

                    &:first-child{ border-left-color: var(--dynamic__table-header-background)}
                    &:last-child{ border-right-color: var(--dynamic__table-header-background)}
                }
                td{
                  &.secondary-header{
                    background-color: var(--dynamic__table-secondary-header-background);
                  }  
                }
            }
        }
    }

    pre{
        font-family: 'Maison Neue';
        white-space: normal;
        font-size: 1.4rem;
        line-height: 1.714285714285714em; // 24px/14px
        color: var(--color-grayscale-gray50);
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .videoWrapper{
        max-width: 560px;
        .ytVideo {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            iframe{
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .table-wrapper,
    .emphasis{
        // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
        & + pre{
            margin-top: -45px;
        }
        // Modifier la marge pour les tableaux sous les tableaux
        & + .table-wrapper,
        & + .emphasis{
            margin-top: -35px;
        }
    }


    	/* ----- Image - No Caption ----- */

	img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

			/* block */

            display: block;
			margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
            }
        }
    }

	/* ----- Image - With Caption ----- */

    .fr-img-caption {

		img {
			width: 100%;
		}

        &.fr-dib {

			/* block */

            display: block;
			margin: 30px auto;
            float: none;
            vertical-align: middle;
			text-align: center;

            &.fr-fil {
                margin-left: 0;
				text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
				text-align: right;
            }

			.fr-img-wrap {

				.fr-inner {
					display: block;
				}
			}
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
			text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
				text-align: left;
            }

            &.fr-fir {
                float: right;
				margin-right: 0;
                text-align: right;
            }
        }
    }

    span.fr-fic.oc-img-rounded img,
	img.fr-fic.oc-img-rounded {
        border-radius: 100%;
        background-clip: padding-box;
    }

	span.fr-fic.oc-img-bordered img,
    img.fr-fic.oc-img-bordered {
        border: solid 10px #ccc;
    }

}