/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Filtre galerie
 * Galerie de médias
 * Icone bouton plus de médias
 * Icone play
 */

/****** Filtre galerie ******/
.galleries{
    &__filtre{
        padding: 5px;
    }

    /****** Galerie de médias ******/
    &__list{
        display: flex;
        flex-wrap: wrap;
        &Item{
            // 4 médias de large
            width: 25%;
            padding: 0 10px;
            margin: 0 0 40px 0;
            position: relative;
            > a{
                position: relative;
                line-height: 0;
                margin: 0 0 15px 0;
                > img{
                    width: 100%;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    content: "";
                    background: rgba(0, 0, 0, 0.2);
                    transition: all 300ms;
                }
                &:hover{
                    transform: scale(1.01);
                    .play{
                        opacity: 0.2;
                    }
                    &::after {
                        background: rgba(0, 0, 0, 0);
                    }
                }
            }
            > p{
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }
        }
    }
    // 3 médias de large
    @media screen and (max-width: 1200px ){
        &__listItem{
            width: 33.33%;
            margin: 0 0 30px 0;
            padding: 0 10px;
        }
    }
    // 2 médias de large
    @media screen and (max-width: 600px){
        &__listItem{
            width: 50%;
        }
    }
    // 1 média de large
    @media screen and (max-width: 400px){
        &__listItem{
            width: 100%;
            margin: 0 0 20px 0;
            > a{
                margin: 0 0 10px 0;
                > img{
                    max-height: 182px;
                }
            }
        }
    }

    /****** Icone bouton plus de médias ******/
    &__btnMore{
        display: flex;
        justify-content: center;
    }
}

/****** Icone play ******/
.play{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    border: solid 2px #fff;
    z-index: 1;
    opacity: 1;
    border-radius: 50%;
    transition: all 300ms;
    .arrow{
        margin: -8px 0 0 -4px;
        border-width: 8px 0 8px 13.9px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 1;
    }
}






