/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Vidéo - styles bases
 */

/****** Vidéo - styles bases ******/
.video{
    &__titre{
        margin-bottom: 10px;
    }
    &__description{
        margin-top: 10px;
    }
}
