/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Bannière page contact
 * Liste de coordonnées
 */

/****** Bannière page contact ******/
.contact__pageBanner{
    .contact{
        &__content{
            height: 300px;
        }
        &__wrapper{
            position: relative;
            height: 100%;
            picture{
                position: relative;
                height: 100%;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(0deg, rgba(41, 53, 72, 0.9) 0%, rgba(41, 53, 72, 0) 100%);
                    z-index: 1;
                }
            }
            h1 {
                position: absolute;
                bottom: 70px;
                color: white;
                z-index: 2;
            }
        }
    }
    @media screen and (max-width: 600px){
        .contact__content{
            height: 200px;
        }
        .contact__wrapper h1{
            bottom: 45px;
        }
    }
}


/****** Liste de coordonnées ******/
// Voir module-lexicons.scss
