:root {
  --header-top-section-height: 44px;
  --header-bottom-section-height: 105px;
  --header-mobile-section-height: 105px;
  --fastlinks-button-mobile-height: 50px;

  @media screen and (max-height: 700px) { --header-bottom-section-height: 80px; }
  @media screen and (max-width: 700px) { --header-bottom-section-height: 103px; }
  @media screen and (max-height: 700px) { --header-mobile-section-height: 80px; }
}

.header{
  @media screen and (max-width: 1300px), screen and (max-height: 600px) { display: none; }

  // Section du haut
  &__top-section{
    height: var(--header-top-section-height);
    border-bottom: 1px solid #D0DEE1;
    padding: 0 var(--x-spacing-80);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-height: 700px) { display: none; }
  }

  &__links-list{
    display: flex;
    justify-content: flex-end;
  }

  &__link-container{ margin-right: 35px; }

  &__link-container:last-child { margin-right: 0; }

  &__link{
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
  }

  //Section du bas
  &__bottom-section{
    height: var(--header-bottom-section-height);
    padding: 0 var(--x-spacing-80);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-container{
    width: 180px;
    height: 46px;
    margin-right: 40px;
    display: block;
  }

  &__logo{
    width: 100%;
    height: 100%;
  }

  &__item-right{
    display: flex;
    align-items: center;
  }

  &__search{ margin-right: 15px; }

  &__fast-links { white-space: nowrap; }

  &__primary-nav {display: flex; }

  &__primary-link {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 38px;
    cursor: pointer;
  }
}

.header-mobile{
  display: none;

  @media screen and (max-width: 1300px), screen and (max-height: 600px) { display: block; }

  &__section{
    height: var(--header-mobile-section-height);
    padding: 0 var(--x-spacing-80);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-container{
    width: 180px;
    height: 46px;
    display: block;

    @media screen and (max-width: 440px) { width: 160px; height: 41px; }
    @media screen and (max-width: 400px) { width: 110px; height: 28px; }
  }

  &__logo {
    width: 100%;
    height: 100%;
  }

  &__item-right{
    display: flex;
    align-items: center;
  }

  &__search, &__menu{ margin-right: 15px; }

  &__menu{ @media screen and (max-width: 700px) { margin-right: 0; } }

  &__search { @media screen and (max-width: 400px) { margin-right: 10px; } }

  &__fast-links { @media screen and (max-width: 700px) { display: none!important; } } // Fast-links desktop

  &__fast-links-mobile{
    height: var(--fastlinks-button-mobile-height);
    display: none;
    width: 100%;
    color: white;
    position: relative;
    background-color: var(--button-background);
    font-weight: 600;
    font-size: 1.4rem;
    padding: 15px var(--x-spacing-80);
    align-items: center;
    line-height: 1.3em;

    @media screen and (max-width: 700px) { display: inline-flex; }
  }

  &__open {
    opacity: 1;
    transition: opacity 300ms;
  }

  &__close {
    opacity: 0;
    transition: opacity 300ms;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  &__close--mobile {
    left: var(--x-padding-80);
    transform: translateY(-50%);
  }

  &__fast-links-mobile-svg {
    width: 12px;
    height: 6px;
    fill: white;
    margin-left: 15px;
    transition: transform 300ms;
  }
}