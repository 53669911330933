.footer{

  &__padding{
    padding-left: var(--x-spacing-60);
    padding-right: var(--x-spacing-60);
  }

  &__wrapper{
    margin: auto;
    max-width: 1320px;
  }

  &__breadcrumbs{
    padding: var(--y-spacing-50) 20px;
    overflow: auto;

    @media screen and (max-width: 1024px){
      padding-left: 0;
      padding-right: 0;
    }

    &::-webkit-scrollbar{
      -webkit-appearance: none;
      width: 5px;
      height: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      background-color: black;
    }
  }

  &__content{
    border-top: 1px solid #D0DEE1;
    border-bottom: 1px solid #D0DEE1;
    padding: var(--y-spacing-60) 0;

    @media screen and (max-width: 1024px) {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__grid{
    display: flex;
  }

  &__logo-blanko{
    display: block;
    @media screen and (max-width: 1024px) { display: none; }
  }

  &__logo-svg{
    width: 130px;
    height: 34px;
  }

  &__nav{
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;

    margin-bottom: -22px;
  }

  &__nav-item{
    margin-bottom: 22px;
  }

  &__nav-link{
    font-size: 1.4rem;
    display: block;
    color: black;
  }

  &__contact-info{
    font-size: 1.4rem;
    line-height: 1.85em;

    a{ color: black; }
  }

  &__contact-info-separation{
    margin: 0 12px;
  }

  &__contact-button{
    margin-top: 15px;
  }

  &__column1{
    margin-right: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1300px) { margin-right: 60px; }
    @media screen and (max-width: 768px) { margin: 0 auto; }
  }

  &__column2{
    margin: 0 auto;
    @media screen and (max-width: 1024px) { display: none; }
  }

  &__column3{
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1024px) { display: none; }
  }

  &__copyright{
    font-size: 1.2rem;
    padding: var(--y-spacing-50) 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__copyright-text,
  &__copyright-politics{
    margin-right: 40px;
  }

  &__copyright-text a,
  &__copyright-politics a,
  &__copyright-signature a{
    color: black
  }

  &__copyright-politics a{
    font-weight: bold;
  }

  &__copyright-signature{
    margin-left: auto;
  }

  @media screen and (max-width: 768px) {
    --social__size: 55px;
    --social__icon-size: 18px;

    &__copyright{
      margin-top: -20px;
    }

    &__copyright-text,
    &__copyright-politics,
    &__copyright-signature{
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      padding-top: 20px;

      a{ color: black }
    }

    &__copyright-text{ order: 2; }
    &__copyright-politics{  order: 1; }
    &__copyright-signature{  order: 3; }
  }
}