.thumb{

    &__picture{
        width: 100%;
        padding-bottom: 100%;
        background-color: #F5F5F5;
        position: relative;
    }

    &__picture-text{
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: rem(11);
        background-color: #2225E9;
        font-weight: 500;
        padding: 14px 24px;
        z-index: 2;
    }

    &__picture-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        picture{
            height: 100%;
            width: 100%;
            overflow: hidden;
            img{
                transform: scale(1);
                transition: transform 0.3s;
            }
        }
    }

    &__text{
        position: relative;
        padding-top: 22px;
        padding-bottom: 22px;
        color: black;
    }

    &__title{
        font-size: rem(25);
        font-weight: bold;
    }
}