:root {
  --left-box-width: 160px;
}

.overlay-fastlinks{
  width: 0%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  &__background{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    transition: background 0.8s var(--ease-out-quart);
    z-index: -1;
  }

  &__container{
    opacity: 0;
    background: white;
    transform: translateY(-100px);
    transition: transform 0.8s var(--ease-out-quart), opacity 0.8s var(--ease-out-quart);
    padding-top: var(--y-spacing-100);
    padding-bottom: var(--y-spacing-100);
    padding-left: var(--x-spacing-80);

    @media screen and (min-width: 1801px) { padding-left: 15vw; }
    @media screen and (max-width: 600px) {
      padding-top: var(--y-spacing-40);
      padding-bottom: var(--y-spacing-40);
    }
  }

  &__slider{
    width: calc(100% - var(--left-box-width));
    margin-bottom: 0 !important;

    @media screen and (max-width: 768px) { width: 100%; }
  }

  &__slider-container{
    display: flex;
    overflow: hidden;

    .slider-nav{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__link:first-child{
        margin-bottom: 40px;
      }
    }
  }

  &__link{
    padding: 50px 200px 50px 60px;
    margin-right: 45px;
    background: #458A91;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 600px) { padding: 20px 110px 20px 20px; }
  }

  &__circle{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #58969c;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #58969c;
      position: absolute;
      z-index: 1;
    }

    @media screen and (max-width: 600px) {
      width: 140px;
      height: 140px;
    }
  }

  &__icon{
    width: 50px;
    height: 50px;
    stroke: white;
    stroke-width: 2px;
    fill: none;
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    will-change: transform;
    z-index: 2;

    @media screen and (max-width: 600px) {
      width: 40px;
      height: 40px;
      left: 25px;
    }
  }

  &__text {
    width: 190px;
    font-size: 2.2rem;
    line-height: 1.4;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 120px;
    transform: translateY(-50%);
    will-change: transform;
    z-index: 2;

    @media screen and (max-width: 600px) {
      width: 140px;
      left: 85px;
      font-size: 1.5rem;
      line-height: 1.7;
    }
  }

  &__left-box {
    width: var(--left-box-width);

    @media screen and (max-width: 768px) { display: none; }
  }
}