/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Links header - styles bases (présent dans module-header.scss)
 * Links overlays - styles bases
 */

/****** Links overlays - styles bases ******/
// Lien contrôle
.m_btnFastLinks{
    position: fixed;
    z-index: 30;
    bottom: 25px;
    left: 40px;
    background: #ff8400;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    > span {
        text-align: center;
        color: white;
        &.state1 {
            display: block;
        }
        &.state2 {
            display: none;
        }
    }
    @media screen and (max-width: 600px) {
        width: 70px;
        height: 70px;
        right: 25px;
        left: auto;
    }
}

// Lien contrôle - actif
.showOverlayFastLinks .m_btnFastLinks {
    > span{
        &.state1 {
            display: none;
        }
        &.state2 {
            display: block;
        }
    }
}

// Box overlay links
#overlayFastLinks {
    .wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #f3a450;
        text-align: center;
        z-index: 20;
        display: none;
        > div {
            width: 100%;
            max-width: 850px;
            padding: 50px 0;
            margin: 0 auto;
            .column {
                width: 33.33333%;
                &:nth-child(-n + 3) {
                    margin-top: 0;
                }
                &:not(:nth-child(-n + 3)) {
                    margin-top: 40px;
                }
            }
            a {
                width: 240px;
                height: 240px;
                position: relative;
                span {
                    position: absolute;
                    width: 100%;
                    padding: 0 12px;
                    top: 50%;
                    left: 0;
                    font-size: 2.18rem;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    transform: translateY(-50%);
                    z-index: 2;
                }
                picture, img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    overflow: hidden;
                }
                picture{
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(41, 53, 72, 0.6);
                        z-index: 1;
                        transition: background 300ms;
                    }
                }
            }
        }
    }
    .grid {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 600px) {
        .wrapper > div{
            max-width: 400px;
            .column {
                width: 50%;
                &:nth-child(-n + 2) {
                    margin-top: 0;
                }
                &:not(:nth-child(-n + 2)) {
                    margin-top: 40px;
                }
            }
            a{
                width: 150px;
                height: 150px;
                span {
                    font-size: 1.5rem;
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        .wrapper > div {
            max-width: 500px;
            .column {
                width: 50%;
                &:nth-child(-n + 2) {
                    margin-top: 0;
                }
                &:not(:nth-child(-n + 2)) {
                    margin-top: 40px;
                }
            }
            a{
                width: 200px;
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .wrapper > div a{
            width: 200px;
            height: 200px;
        }
        .grid {
            display: flex;
            justify-content: space-around;

        }
    }
}

// Box overlay links - actif
.showOverlayFastLinks {
    #overlayFastLinks .wrapper {
        display: flex;
        align-items: center;
    }
}









