.list{
    //@extend .x-padding-80;
    //@extend .top-padding-70;
    //@extend .bottom-padding-100;
    background-color: white;

    &__count{
        //@extend .bottom-padding-50;
        font-size: rem(22);
        color: black;
    }

    &__items{
        margin-bottom: calc(var(--grid-bigger-gaps)*-2);
        .column{
            padding-bottom: calc(var(--grid-bigger-gaps)*2);
        }
    }

    &__item{
        width: 100%;
    }

    &__pagination{
        //@extend .top-padding-100;
    }
}