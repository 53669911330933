:root{
  --breadcrumbs__spacing: 30px;
}

.breadcrumbs{
  font-size: 1.4rem;

  &__list{
    display: flex;
  }

  &__item{
    position: relative;
    &:not(:last-child){
      padding-right: var(--breadcrumbs__spacing);
      &::after{
        content: '/';
        position: absolute;
        right: calc(var(--breadcrumbs__spacing)/2);
        top: 50%;
        transform: translate(50%, -50%);
      }
    }
  }

  &__text{
    color: black;
    white-space: nowrap;
  }

  &__item:last-child &__text{
    font-weight: 600;
  }
}