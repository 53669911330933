/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE - Fastlinks
|--------------------------------------------------------------------------
*/
.show-overlay-fastlinks {

  .overlay-fastlinks {
    width: 100%;
    top: calc(var(--header-top-section-height) + var(--header-bottom-section-height));
    bottom: 0;

    @media screen and (max-width: 1300px) { top: var(--header-mobile-section-height); }
    @media screen and (max-width: 700px) { top: calc(var(--header-mobile-section-height) + var(--fastlinks-button-mobile-height)); }

    &__background { background: rgba(#000, 0.4); }

    &__container {
      opacity: 1;
      transform: none;
    }
  }

  .header-mobile__fast-links-mobile, .header-mobile__fast-links, .header__fast-links {
    background: black;
    border-color: black;

    .header-mobile__fast-links-mobile-svg { transform: rotate(180deg); }
    .header-mobile__open { opacity: 0; }
    .header-mobile__close, .header-mobile__close--mobile { opacity: 1; }
  }
}

/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME - Fastlinks
|--------------------------------------------------------------------------
*/
.show-overlay-fastlinks.closing-overlay-fastlinks {
  .overlay-fastlinks {
    &__background { background: rgba(#000, 0); }
    &__container {
      opacity: 0;
      transform: translateY(-100px);
    }
  }

  .header-mobile__fast-links-mobile, .header-mobile__fast-links, .header__fast-links {
    background: var(--button-background);
    border-color: var(--button-background);

    .header-mobile__fast-links-mobile-svg { transform: rotate(0deg); }
    .header-mobile__open { opacity: 1; }
    .header-mobile__close, .header-mobile__close--mobile { opacity: 0; }
  }
}