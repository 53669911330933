/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Banniere - styles bases
 */


/****** Banniere - styles bases ******/
.bannerBlock {
    position: relative;
    width: 100%;
    .bannerNavigation {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 30px;
        z-index: 1;
        .slick-dots {
            position: absolute;
            top: 50%;
            bottom: auto;
            font-size: 0;
            transform: translateY(-50%);
            li {
                width: auto;
                height: auto;
                margin: 0;
                &:not(:last-child) { margin-bottom: 20px; }
                &.slick-active button { background: #fff; }
                button {
                    width: 5px;
                    height: 5px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    transition: background 300ms;
                    &:before { display: none; }
                }
            }
        }

        @media screen and (max-width: 800px){
            top: auto;
            right: 0;
            bottom: 35px;
            left: 0;
            .slick-dots li:not(:last-child) {
                margin-bottom: 0; margin-right: 15px;
            }
        }
    }

    .slickBanner {
        margin-bottom: 0;

        .banner {
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100vh - 110px);
            min-height: 500px;

            @media screen and (max-height: 600px) { height: calc(100vh - 80px); }
            @media screen and (max-width: 1024px) { height: calc(100vh - 80px); }
            @media screen and (max-width: 768px) { height: calc(100vh - (80px + 180px)); min-height: 315px; }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#000, 0.35);
            }
            .flex {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;

                .wrapper {
                    width: 100%;
                    text-align: center;
                    padding: 0 20px;
                    h1 {
                        color: white;
                        font-size: rem(60);
                        @media screen and (max-width: 1600px) { font-size: rem(85); }
                        @media screen and (max-height: 750px) { font-size: rem(80); }
                        @media screen and (max-width: 1400px) { font-size: rem(80); }
                        @media screen and (max-height: 700px) { font-size: rem(65); }
                        @media screen and (max-width: 1200px) { font-size: rem(65); }
                        @media screen and (max-height: 660px) { font-size: rem(55); }
                        @media screen and (max-width: 1024px) { font-size: rem(55); }
                        @media screen and (max-width: 768px)  { font-size: rem(45); }
                        @media screen and (max-width: 425px)  { font-size: rem(40); }
                        @media screen and (max-width: 375px)  { font-size: rem(35); }
                        @media screen and (max-width: 320px)  { font-size: rem(32); }
                        margin-bottom: 20px;
                    }
                    p, time{
                        color: white;
                        font-size: rem(20);
                        margin: 15px 0;
                        &.subtagline{
                            font-size: rem(30);
                            position: relative;
                            padding-bottom: 20px;
                            margin-bottom: 30px;
                            &:after {
                                content: "";
                                position: absolute;
                                width: 120px;
                                height: 2px;
                                bottom: 0;
                                left: 50%;
                                background: #007bff;
                                transform: translateX(-50%);
                            }
                        }
                    }
                    a {
                        margin-top: 25px;
                        @media screen and (max-width: 500px) { margin-top: 30px; }
                        @media screen and (max-width: 375px) { margin-top: 20px; }
                    }
                }
            }
        }
    }
}


