/**
 * ------------------
 * TABLE DES MATIÈRES
 * ------------------
 * Liste témoignages
 * Témoignage
 * Si témoignage = link
 */

/****** Liste témoignages ******/
.testimonials{
  &__grid{
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 1% + 1%);
    margin-left: -1%;
    margin-right: -1%;
  }

  &__column{
    padding-right: 1%;
    padding-left: 1%;
    padding-bottom: 1em;
    width: 33.33333%;
    @media screen and (max-width: 900px){ width: 50%; }
    @media screen and (max-width: 700px){ width: 100%; }
  }
}

/****** Témoignage ******/
.testimonial{
  height: 100%;
  width: 100%;
  background-color: #f7f8f8;
  border-radius: 7px;
  border: 1px solid #d6d8d9;
  padding: 35px 30px;
  transition: background-color ease;

  &__wrapper{
    position: relative;
    height: 100%
  }

  &__title{
    font-size: 18px;
    font-weight: 500;
    color: #1f78c1;
    margin-bottom: 18px;
  }

  &__content{
    font-size: 13px;
    font-weight: 500;
    color: #012040;
  }

  &__button{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

/****** Si témoignage = link ******/
a.testimonial{
  &:hover{
    background-color: #eef4f9;
  }

  .testimonial__wrapper{
    padding-bottom: 90px;
  }
}