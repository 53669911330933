.main{
    background-color: #F5F5F5;
    position: relative;

    &__content{
        //@extend .x-padding-180;
        //@extend .y-padding-100;
        position: relative;
        background: transparent;
        overflow-x: hidden;

        &__top{
            &__intro{
                //@include fontsize(35,  0, 30, 25, 22, 21, 20);
                color: black;
                line-height: 1.4285714em; //50px/35px
                //@extend .bottom-padding-40;
            }
        }
    }
}